import Chef from "./chefAbi";
import {SwapPlatforms} from "@/config/swapPlatforms";

export const config = {
    name: "PolyDragon",
    url: "https://polydragon.gold/",
    chef: {
        address: "0x36caee4b022f5a0f5CE31fDb7FDF45498E8b2678",
        rewardTokenTicker: "DGold",
        abi: Chef,
        rewardTokenFunction: "sh",
        pendingRewardsFunction: "pendingSh",
        claimFunction: "claim"
    },
    swap: SwapPlatforms.OneInch
}

export default config;
